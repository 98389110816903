import React, {useEffect, useState} from 'react';
import {Button, Container} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {onAuthStateChanged, signOut, User} from "firebase/auth";
import {auth} from "../../index";

function Account() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (!user) {
        navigate('/account/login')
      }
    });
  }, []);

  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Erfolgreich ausgeloggt!");
        setUser(null);
      })
      .catch((error) => {
        console.error("Logout fehlgeschlagen!");
        console.error(error.code);
        console.error(error.message);
      })
  }


  return (
    <Container className="account__container">
      {user && <>
          <h1>Mein Konto</h1>
          <p>Schön das du da bist! Noch kannst du mit deinem Konto bei uns nicht viel machen. In Zukunft findest du hier
              deine gebuchten Kurse, Zahlungseinstellungen und weiteres.</p>
          <Button onClick={handleLogout} type="button">Abmelden</Button>
      </>}


    </Container>
  )
}

export default Account;
