import React, {useState} from 'react';
import {Icon, Menu, Segment} from "semantic-ui-react";
import './Navigation.scss';
import {useLocation, useNavigate} from "react-router-dom";
import {createMedia} from "@artsy/fresnel";


const {MediaContextProvider, Media} = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

function Navigation() {

  function NavigationItems() {
    const location = useLocation();
    const initialPathname = location.pathname.replace('/', '');
    const [activeItem, setActiveItem] = useState(initialPathname)


    const navigate = useNavigate();

    const handleClick = (item: string) => {
      setActiveItem(item);
      navigate(item);
    }


    return (
      <>
        <Menu.Item active={activeItem === ''} onClick={() => handleClick('')}>
          Gin Aus´Lese
        </Menu.Item>
        <Menu.Item active={activeItem === 'wir'} onClick={() => handleClick('wir')}>
          Das sind wir
        </Menu.Item>
        <Menu.Item active={activeItem === 'galerie'} onClick={() => handleClick('galerie')}>
          Galerie
        </Menu.Item>
        <Menu.Item active={activeItem === 'kurse'} onClick={() => handleClick('kurse')}>
          Kurse
        </Menu.Item>
      </>
    )
  }

  function SocialMediaItems() {
    const URL_YOUTUBE = 'https://www.youtube.com/channel/UCo7FOpyMM1lOkVAIC0HvBNA'
    const URL_INSTAGRAM = 'https://www.instagram.com/ginaus_lese'
    const URL_FACEBOOK = 'https://www.facebook.com/pages/category/Blogger/Ginauslese-105986704727050'

    const openSocialMedia = (url: string) => {
      window.open(url, '_blank')?.focus();
    }

    const navigate = useNavigate();

    const handleUserButton = () => {
      navigate('/account');
    }

    return (
      <>
        <Menu.Item className="navigation__youtube-link" position="right" onClick={() => openSocialMedia(URL_YOUTUBE)}>
          <Icon name='youtube' size='large'/>
        </Menu.Item>
        <Menu.Item className="navigation__instagram-link" onClick={() => openSocialMedia(URL_INSTAGRAM)}>
          <Icon name='instagram' size='large'/>
        </Menu.Item>
        <Menu.Item className="navigation__facebook-link" onClick={() => openSocialMedia(URL_FACEBOOK)}>
          <Icon name='facebook' size='large'/>
        </Menu.Item>
        <Menu.Item onClick={handleUserButton}>
          <Icon name='user' size='large'/>
        </Menu.Item>
      </>
    );
  }

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  function openBurgerMenu() {
    setBurgerMenuOpen(!burgerMenuOpen);
  }

  const burgerMenuClassName = (): string => {
    return burgerMenuOpen
      ? 'navigation__burger-menu navigation__burger-menu--open'
      : 'navigation__burger-menu navigation__burger-menu--closed';
  }

  return (
    <Segment inverted className="navigation">
      <MediaContextProvider>
        <Media greaterThan='mobile'>
          <Menu inverted secondary>
            <NavigationItems/>
            <SocialMediaItems/>
          </Menu>
        </Media>
        <Media at='mobile'>
          <Menu inverted secondary>
            <Menu.Item onClick={() => openBurgerMenu()}>
              <Icon name='bars' size='large'/>
            </Menu.Item>
            <SocialMediaItems/>
          </Menu>
          <Menu inverted secondary vertical className={burgerMenuClassName()}>
            <NavigationItems/>
          </Menu>
        </Media>
      </MediaContextProvider>
    </Segment>
  );
}

export default Navigation;
