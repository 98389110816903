import React from 'react';
import {Segment} from "semantic-ui-react";
import './Home.scss';
import YouTube, {Options} from "react-youtube";

function Home() {

  const playerOpts: Options = {
    playerVars: {
      autoplay: 1,
    }
  }

  const onPlayerReady = (event: any) => {
    event.target.mute();
    event.target.playVideo();
  }

  return (
    <div>
      <Segment vertical>
        <div className="home__video-container">
          <YouTube
            videoId="l6dLqLyi-vg"
            opts={playerOpts}
            onReady={onPlayerReady}
          />
        </div>
      </Segment>
    </div>
  )
}

export default Home;
