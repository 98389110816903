import React, {useState} from 'react';
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../index";
import {Button, Container, Icon, Input, Message} from "semantic-ui-react";
import './Login.scss';
import {useNavigate} from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  function handleRegister(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setError("");
    setSuccess(false);

    if (!(name && mail && password && repeatedPassword)) {
      return;
    }

    if (password !== repeatedPassword) {
      setError("Die Passwörter stimmen nicht überein!");
      return;
    }

    createUserWithEmailAndPassword(auth, mail, password)
      .then((userCredentials) => {
        console.log("Erfolgreich registiert!");
        console.log(userCredentials.user);
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Registiert fehlgeschlagen!");
        console.error(error.code);
        console.error(error.message);
        setError("Bei der Registierung ist ein Fehler aufgetreten!")
      })
  }

  function changeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName((event.target as HTMLInputElement).value)
  }

  function changeMail(event: React.ChangeEvent<HTMLInputElement>) {
    setMail((event.target as HTMLInputElement).value)
  }

  function changePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword((event.target as HTMLInputElement).value)
  }

  function changeRepeatedPassword(event: React.ChangeEvent<HTMLInputElement>) {
    setRepeatedPassword((event.target as HTMLInputElement).value)
  }

  return (
    <Container className="login__container">
      <h1>Neues Konto anlegen</h1>
      {!success &&
      <form onSubmit={handleRegister} className="login__form">
          <Input required type="text" icon="user" iconPosition='left' placeholder="Name" value={name}
                 onChange={changeName}/>
          <Input required type="email" icon="mail" iconPosition='left' placeholder="E-Mail" value={mail}
                 onChange={changeMail}/>
          <Input required minLength="6" type="password" icon="key" iconPosition='left' placeholder="Passwort"
                 value={password}
                 onChange={changePassword}/>
          <Input required minLength="6" type="password" icon="key" iconPosition='left'
                 placeholder="Passwort wiederholen"
                 value={repeatedPassword} onChange={changeRepeatedPassword}/>
          <Button type="submit">Absenden</Button>
      </form>
      }

      <div className="login__actions">
        {success && <Message color='green'><Icon name="check"/>Dein neues Konto wurde erfolgreich angelegt.</Message>}
        {error && <Message color='red'><Icon name="warning sign"/>{error}</Message>}
        <Button className="login__toggle" size='mini' secondary onClick={() => navigate('/account/login')}>
          Ich habe bereits ein Konto
        </Button>
        <Button className="login__toggle" size='mini' secondary onClick={() => navigate('/account/reset')}>
          Passwort vergessen?
        </Button>
      </div>

    </Container>
  )
}

export default Login;
