import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.css'
import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage";
import {getAnalytics} from "firebase/analytics";
import {BrowserRouter} from "react-router-dom";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCryy8AZZl0I0_oK5iw_ysfN9OPIfZx4iI",
  authDomain: "ginauslese-2022.firebaseapp.com",
  projectId: "ginauslese-2022",
  storageBucket: "ginauslese-2022.appspot.com",
  messagingSenderId: "1033456024905",
  appId: "1:1033456024905:web:5220443aabd9c3e4242e98",
  measurementId: "G-21ECCBNMG4"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
const analytics = getAnalytics(app);

ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
