import React from 'react';
import {Header, Image} from "semantic-ui-react";
import './Courses.scss';

function Courses() {
  return (
    <div className="courses">
      <Header className="courses__header" as="h1">» Hier findest du in Zukunft unsere Kurse «</Header>
      <Image src='../assets/commingsoon.jpeg' size="big"/>
    </div>
  )
}

export default Courses;
