import React from 'react';
import {Link} from "react-router-dom";
import './Footer.scss'
import {Container} from "semantic-ui-react";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <span>Gin Aus´Lese made with ♥ in Lünen | </span>
        <Link to="impressum">Impressum</Link>
      </Container>
    </div>
  )
}

export default Footer;
