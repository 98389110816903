import React from 'react';
import './App.scss';
import Navigation from "./container/Navigation";
import {Container} from "semantic-ui-react";
import {Route, Routes} from "react-router-dom";
import {RecoilRoot} from 'recoil';
import Home from "./pages/Home";
import About from "./pages/About";
import Impress from "./pages/Impress";
import Photos from "./pages/Photos";
import Courses from "./pages/Courses";
import Footer from "./container/Footer";
import Login from "./pages/account/Login";
import Register from "./pages/account/Register";
import Reset from "./pages/account/Reset";
import Account from "./pages/account/Account";

function App() {

  return (
    <RecoilRoot>
      <Container className="app">
        <Navigation/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="wir" element={<About/>}/>
          <Route path="galerie" element={<Photos/>}/>
          <Route path="kurse" element={<Courses/>}/>
          <Route path="impressum" element={<Impress/>}/>
          <Route path="account" element={<Account/>}/>
          <Route path="account/login" element={<Login/>}/>
          <Route path="account/register" element={<Register/>}/>
          <Route path="account/reset" element={<Reset/>}/>
        </Routes>
        <Footer/>
      </Container>
    </RecoilRoot>
  );
}

export default App;
