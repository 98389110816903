import React, {useCallback, useEffect, useState} from 'react';
import Gallery, {PhotoProps} from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import {storage} from "../index";
import {getDownloadURL, listAll, ref} from 'firebase/storage';
import {useRecoilState} from "recoil";
import {photosAtom} from "../stores";

function Photos() {
  const [photoState, setPhotoState] = useRecoilState(photosAtom);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, {photo, index}) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const storageRef = ref(storage, '/photos/');

  function getMeta(url: string) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  }

  function shuffle(a: any[]) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  useEffect(() => {
    if (photoState.length === 0) {
      listAll(storageRef).then(async (result) => {
        const photos: PhotoProps[] = [];
        for (const item of shuffle(result.items)) {
          const url = await getDownloadURL(item);
          const meta: any = await getMeta(url);
          const photo = {
            src: url,
            width: meta.width,
            height: meta.height,
            key: item.name,
          };
          setPhotoState([...photos, photo]);
          photos.push(photo);
        }
        setPhotoState(photos);
      })
    } else {
      setPhotoState(photoState);
    }
  }, []);

  return (
    <div>
      {photoState.length > 0 && <Gallery photos={photoState} onClick={openLightbox} direction={'column'}/>}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photoState.map(x => ({
                ...x,
                source: x.src,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default Photos;
