import React, {useState} from 'react';
import {sendPasswordResetEmail} from "firebase/auth";
import {auth} from "../../index";
import {Button, Container, Icon, Input, Message} from "semantic-ui-react";
import './Login.scss';
import {useNavigate} from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function handleReset(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSuccess(false);
    setError(false);

    if (!mail) {
      return;
    }

    sendPasswordResetEmail(auth, mail)
      .then(() => {
        console.log("Passwort zurücksetzen Mail versendet!");
        setSuccess(true)
      })
      .catch((error) => {
        console.error("Passwort zurücksetzen fehlgeschlagen!");
        console.error(error.code);
        console.error(error.message);
        setError(true);
      })
  }

  function changeMail(event: React.ChangeEvent<HTMLInputElement>) {
    setMail((event.target as HTMLInputElement).value)
  }

  return (
    <Container className="login__container">
      <h1>Passwort zurücksetzen</h1>
      <form onSubmit={handleReset} className="login__form">
        <Input type="email" icon="mail" iconPosition='left' placeholder="E-Mail" value={mail}
               onChange={changeMail}/>
        <Button type="submit">Zurücksetzen</Button>
      </form>

      <div className="login__actions">
        {success && <Message color='green'><Icon name="check"/>Es wurde eine Mail zum zurücksetzen des Passworts
            versendet.</Message>}
        {error && <Message color='red'><Icon name="warning sign"/>Es konnte keine Mail zum zurücksetzen versendet
            werden.</Message>}
        <Button className="login__toggle" size='mini' secondary onClick={() => navigate('/account/register')}>
          Ich möchte ein neues Konto anlegen
        </Button>
        <Button className="login__toggle" size='mini' secondary onClick={() => navigate('/account/login')}>
          Ich habe bereits ein Konto
        </Button>
      </div>

    </Container>
  )
}

export default Login;
