import React, {useState} from 'react';
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../index";
import {Button, Container, Icon, Input, Message} from "semantic-ui-react";
import './Login.scss';
import {useNavigate} from "react-router-dom";

function Login() {

  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    signInWithEmailAndPassword(auth, mail, password)
      .then((userCredentials) => {
        console.log("Erfolgreich eingeloggt!");
        navigate('/account');
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setError("Die Anmeldedaten sind leider falsch!");
        } else {
          setError("Login fehlgeschlagen!");
        }
        console.error(error.code);
        console.error(error.message);
      })

  }


  function changeMail(event: React.ChangeEvent<HTMLInputElement>) {
    setMail((event.target as HTMLInputElement).value)
  }

  function changePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword((event.target as HTMLInputElement).value)
  }

  return (
    <Container className="login__container">
      <h1>Mein Konto</h1>
      <form onSubmit={handleLogin} className="login__form">
        <Input required type="email" icon="mail" iconPosition='left' placeholder="E-Mail" value={mail}
               onChange={changeMail}/>
        <Input required type="password" icon="key" iconPosition='left' placeholder="Passwort" value={password}
               onChange={changePassword}/>
        <Button type="submit">Login</Button>
      </form>

      <div className="login__actions">
        {error && <Message color='red'><Icon name="warning sign"/>{error}</Message>}
        <Button className="login__toggle" size='mini' secondary onClick={() => navigate('/account/register')}>
          Ich möchte ein neues Konto anlegen
        </Button>
        <Button className="login__toggle" size='mini' secondary onClick={() => navigate('/account/reset')}>
          Passwort vergessen?
        </Button>
      </div>
    </Container>
  )
}

export default Login;
