import React from 'react';
import {Container, Header, Image} from "semantic-ui-react";
import './About.scss';

function About() {
  return (
    <Container className="about__container">
      <Header as="h1" textAlign="center">Moin Moin an alle Gin-Liebhaber, Gin-Gourmets und einfach an alle die gerne Gin
        trinken und
        Herzlich Willkommen auf unserer Homepage.</Header>
      <div className="about__flex">
        <Image className="about__photo" src='../assets/lese.jpeg' size="large"/>
        <Image className="about__photo" src='../assets/sascha.jpg' size="large"/>
      </div>
      <div>
        <p className="about__text">Wir sind Sascha und Lese, zwei Bauingenieure die Ihre Leidenschaft zum GIN mit euch
          teilen wollen. Bei uns
          findet Ihr alles rund ums Thema GIN. Wir präsentieren euch in regelmäßigen Abständen unterschiedlichste Gins
          und
          die dazugehörigen Botanicals und Tonics. Ihr habt euch schon immer gefragt, welcher Gin zu welchem Tonic
          passt?
          Welche Botanicals zu welchem Gin gehören oder einfach was man alles für den perfekten Gin benötigt? Dann seid
          ihr hier bei der Gin Aus´Lese genau richtig!!!</p>
      </div>

    </Container>
  )
}

export default About;
